import React from "react";
import { Row, Spin } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { useSelector } from "react-redux";

// DEFINE MAIN FUNCTION
const Chart = ({ activityFilter, analyticsData }) => {
  console.log("analyticsData", analyticsData);
  let detailsOfEmailAnalytics = useSelector(
    (state) => state.dashboard.emailAnalyticsDetails
  );
  console.log("detailsOfEmailAnalytics", detailsOfEmailAnalytics);
  let todayAnalyticsData = detailsOfEmailAnalytics?.today;
  console.log("todayAnalyticsData", todayAnalyticsData);
  // DEFINE ALL STATES
  const data = [
    { name: "Jan", send_today: 12, receive_today: 5 },
    { name: "Feb", send_today: 15, receive_today: 7 },
    { name: "Mar", send_today: 10, receive_today: 1 },
    { name: "Apr", send_today: 17, receive_today: 3 },
    { name: "May", send_today: 14, receive_today: 5 },
  ];

  // DEFINE FUNCTION FOR TOOLTIP
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <>
          {activityFilter == "activity" ? (
            <div className="customeToolTip">
              <Row justify={"space-between"}>
                <span>Sent</span>
                <span>
                  {payload[0]?.payload?.send_today
                    ? payload[0].payload?.send_today
                    : 0}
                </span>
              </Row>
              <Row justify={"space-between"}>
                <span>Received</span>
                <span>
                  {payload[1]?.payload?.receive_today
                    ? payload[1]?.payload?.receive_today
                    : 0}
                </span>
              </Row>
            </div>
          ) : (
            <div className="customeToolTip">
              <Row justify={"space-between"}>
                <span>Email sent</span>
                <span>
                  {payload[0]?.payload?.send_today
                    ? payload[0].payload?.send_today
                    : 0}
                </span>
              </Row>
              <Row justify={"space-between"}>
                <span>Landed in primary</span>
                <span>
                  {payload[0]?.payload?.landed_in_primary
                    ? payload[0]?.payload?.landed_in_primary
                    : 0}
                </span>
              </Row>
              <Row justify={"space-between"}>
                <span>Landed in spam</span>
                <span>
                  {payload[0]?.payload?.landed_in_spam
                    ? payload[0]?.payload?.landed_in_spam
                    : 0}
                </span>
              </Row>
            </div>
          )}
        </>
      );
    }
  };
  // DEFINE MAIN FUNCTION RETURN
  return (
    <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer width="100%" height={200}>
        {!analyticsData && (
          <div className="loaderContainer_warmup_setting">
            <Spin />
          </div>
        )}
        {analyticsData && (
          <LineChart
            width={600}
            height={200}
            data={analyticsData && analyticsData}
            margin={{ top: 40, right: 30, left: -30, bottom: 0 }}
          >
            <XAxis dataKey="name" padding={{ left: 20, right: 30 }} />
            <YAxis
              axisLine={false}
              padding={{ bottom: 2 }}
              style={{ paddingRight: "10px" }}
              tickFormatter={(value) => {
                console.log("value", value * 25);
                return activityFilter == "deliverability"
                  ? `${value}%`
                  : `${value}`;
              }}
            />
            <CartesianGrid stroke="#EAECF0" vertical={false} />

            <>
              <Line
                type="monotone"
                dataKey="deliverability"
                stroke="#09DE35"
                activeDot={{ r: 5 }}
              />
              {/* <Line
              type="monotone"
              dataKey="send_today"
              stroke="#000000"
              activeDot={{ r: 5 }}
            /> */}
              {/* <Line
              type="monotone"
              dataKey="landed_in_primary"
              stroke="#EEA212"
              activeDot={{ r: 5 }}
            /> */}
            </>
            {/* activity filter */}
            {activityFilter == "activity" && (
              <>
                <Line
                  type="monotone"
                  dataKey="send_today"
                  stroke="#09DE35"
                  activeDot={{ r: 5 }}
                />
                <Line
                  type="monotone"
                  dataKey="receive_today"
                  stroke="#EEA212"
                  activeDot={{ r: 5 }}
                />
              </>
            )}
            <Tooltip
              content={<CustomTooltip payload={analyticsData} />}
              cursor={{
                stroke: "#EAECF0",
                strokeWidth: 2,
                strokeDasharray: "5 5",
              }}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

// DEFINE MAIN FUNCION EXPORT
export default Chart;
